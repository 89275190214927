import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseInit";

export class User {
	constructor(params = {}) {
		const defaults = {
			uid: "",
			first: "",
			last: "",
			email: "",
			phone: "",
			publicProfile: "",
			activeOrgId: ""
		}

		const mergedParams = _.merge({}, defaults, params);

		this.uid = mergedParams.uid;
		this.first = mergedParams.last;
		this.last = mergedParams.last;
		this.email = mergedParams.email;
		this.phone = mergedParams.phone;
		this.publicProfile = mergedParams.publicProfile;
		this.activeOrgId = mergedParams.activeOrgId;
	}

	toPlainObject() {
		return _.cloneDeep(_.toPlainObject(this));
	}

	async save() {
		localStorage.setItem("userProfile", JSON.stringify(this.toPlainObject()));
		let saved = await setDoc(doc(db, 'users', this.uid), this.toPlainObject());
	}

}