import { doc, getDoc, setDoc } from 'firebase/firestore';
import { v4 as uuid } from 'uuid';
import { db } from '../firebaseInit';

export class PublicProfile {
	constructor(params = {}) {
		const defaults = {
			id: uuid(),
			type: "",
			coll: "",
			displayName: "",
			proQuote: "",
			description: "",
			picUrl: "../../../assets/img/team/avatar.webp",
			bannerPicUrl: "",
			thumbUrl: "",
			lastViewed: ""
		}

		const mergedParams = _.merge({}, defaults, params);

		this.id = mergedParams.id;
		this.type = mergedParams.type;
		this.coll = mergedParams.coll;
		this.displayName = mergedParams.displayName;
		this.proQuote = mergedParams.proQuote;
		this.description = mergedParams.description;
		this.picUrl = mergedParams.picUrl;
		this.bannerPicUrl = mergedParams.bannerPicUrl;
		this.thumbUrl = mergedParams.thumbUrl;
		this.lastViewed = mergedParams.lastViewed;
	}

	/**
	 * 
	 * @param {string} id - public profile Id to retrieve
	 */
	static async getProfile(id) {
		const proSnap = await getDoc(doc(db, 'publicProfiles', id));
		let p = new PublicProfile(proSnap.data());
		return p;
	}

	toPlainObject() {
		return _.cloneDeep(_.toPlainObject(this));
	}

	/**
	 * @function Creates the internal user profile and public profile
	 * @param {*} user user profile to be linked to the public profile
	 */
	static async createPublicProfile(user) {
		console.log(user);

	}

	async save() {
		let savedProfile = await setDoc(doc(db, 'publicProfiles', this.id), this.toPlainObject());
	}
}