/* eslint-disable */
import jqueryGlobal from './jqueryGlobal.js';
import utils, { docReady } from '../utils.js';
import { app, db } from './firebaseInit.js';
import { collection, setDoc, doc, getDoc, addDoc } from "firebase/firestore"; 
import { getAuth, signInWithRedirect, GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { saveUserProfile, userProfile, setActiveUserProfile, publicProfile, setActivePublicProfile } from './profile.js';
import { checkCookie, setCookie } from './rfutil.js';
import { PublicProfile } from './models/PublicProfile.js';
import { User } from './models/User.js';
import { env, setNotificationStatus } from './localStorage.js';

//const analytics = getAnalytics(app);
const auth = getAuth();

async function signUpGoogle() {
	localStorage.setItem('loginStatus', 'pending');
	getLoginStatus();
	const provider = new GoogleAuthProvider();
	provider.addScope('https://www.googleapis.com/auth/plus.login');
	provider.addScope('https://www.googleapis.com/auth/firebase.messaging');
	//if (env().browser == "Safari" && env().platform == "iPhone") {
		//console.log("Signing in with redirect...");
		//signInWithRedirect(auth, provider);
		
	//} else {
		console.log("Signing in with popup...");
		signInWithPopup(auth, provider)
			.then( (result) => {
				if (result != null) {
					console.log("signed in set from signInWithPopup");
					// This gives you a Google Access Token. You can use it to access the Google API...but why store here and for what purpose?
				} else {
					localStorage.setItem('loginStatus', '');
					getLoginStatus();
					console.log("no result returned from google signinwithpopup")
				}
			})
			.catch((error) => {
				console.log(error);
			});
	//}
}

const signUpFacebook = () => {
    const home = "http://localhost:3002/index.html"
    //const redirUrl = "https://dev.rodeoflo.com"
    Backendless.UserService.getAuthorizationUrlLink('facebook').then(authUrl => {
        location.href = authUrl
    }).catch(error => {
        console.error( 'Error: ', error );
    });

    console.log( 'sign up nav' )
}

// build and save user profile here? yes because I can catch errors prior to allowing entry into site?
// only for mobile? (geRedirectResult)
getRedirectResult(auth)
	.then(async (result) => {
		if ( result !== null ) {
			console.log("signed in set from getRedirectResult")
		} else {
			localStorage.setItem('loginStatus', '');
			getLoginStatus();
		}
	})
	.catch((error) => {
		console.log(error);
	});

//TODO: get user orgProfiles

onAuthStateChanged(auth, async (user) => {
	if (user) {
		//console.log(env());
		console.log(user);
		console.log("1. Starting onAuthStateChanged login....");
		let proSnap = await getDoc(doc(db, "users", user.uid));
		if (proSnap.exists()) {
			setActiveUserProfile(proSnap.data());
			if (userProfile.publicProfile) {
				let pubProSnap = await getDoc(doc(db, "publicProfiles", userProfile.publicProfile));
				setActivePublicProfile(pubProSnap.data());
			}
		} else {
			console.log("profile does not exist...");
			let firstName = user.displayName.split(' ')[0];
			let lastName = user.displayName.split(' ')[1];

			let pubPro = new PublicProfile();
			pubPro.picUrl = user.photoURL;
			pubPro.proQuote = "ready to ride!";
			pubPro.type = "human";
			pubPro.displayName = user.displayName;
			await pubPro.save();
			setActivePublicProfile(pubPro);
			let newUser = new User();
			newUser.uid = user.uid;
			newUser.first = firstName;
			newUser.last = lastName;
			newUser.email = user.email;
			newUser.phone = user.phoneNumber;
			newUser.publicProfile = pubPro.id;
			await newUser.save();
			setActiveUserProfile(newUser);
			
		}
		// logged in with new or existing profile so now init/test for notification permission, service agents, and valid token
		// service workers, tokens, etc. for push notificaitons
		
		//await regServiceWorkers();
		//console.log("service worker function done..");
		//checkNotificationPermission(); // final check before forwarding...

		// had issues with safari forwarding after login so trying .href method instead
		//window.location.replace('/apps/rodeoFlo/users/home.html');

		//TODO change this to appinit loading page...then forward to home
		window.location.href = '/apps/rodeoFlo/users/home.html';
	} else {
		localStorage.removeItem('loginStatus');
		console.log("not logged in from auth state changed function");
	}
})

async function regServiceWorkers() {
	if ('serviceWorker' in navigator) {
		let reg = await navigator.serviceWorker.register('../../../firebase-messaging-sw.js', { scope: '/' });
		console.log("SW Registered: ");
		console.log(reg);
	}
}

async function checkNotificationPermission() {
	if (!("Notification" in window)) {
		console.log("This browser does not support notifications.");
		return;
	}
	switch (Notification.permission) {
		case "default":
			console.log("Notification permission is default (not yet requested).");
			localStorage.setItem('loginStatus', 'signedIn');
			getLoginStatus();
			break;
		case "granted":
			console.log("Notification permission already granted.");
			if (checkCookie("fcmToken")) {
				console.log("fcmToken registered");
				window.location.replace('/apps/rodeoFlo/users/home.html');
			} else {
				console.log("no cookie found...registering device.");
				//getFCMToken();
			}
			// window.location.replace('/apps/rodeoFlo/users/home.html');
			break;
		case "denied":
			console.log("Notification permission is denied...forwarding with no noti's");
			setNotificationStatus("denied");
			window.location.replace('/apps/rodeoFlo/users/home.html');
			break;
		default:
			console.log("Unknown notification permission status.");
	}
}

async function initServiceWorkers() {
	if ('serviceWorker' in navigator) {
		console.log("2. Registering service worker...");
		navigator.serviceWorker.register('../../../firebase-messaging-sw.js', { scope: '/' })
			.then( async (registration) => {
				console.log('Service Worker registered:', registration);
			})
			.catch((error) => {
				console.error('Service Worker registration failed:', error);
			});
	} else {
		console.log("no service worker found...");
	}
}

function getFCMToken() {
	console.log("registering token....");
	let messaging = getMessaging();
	getToken(messaging, {vapidKey: "BMoCn8WXGkDBxqOQTEvWFoMqyhoL0WsmX-EEniclbpVSJFeXwJfvsy3veMjq1LRuyf6SZvm4X8UvKxU7w0b1gI4"})
		.then( async (currentToken) => {
			console.log(currentToken);
			if (currentToken) {
				let tokenObj = {
					created: Date.now(),
					device: navigator.userAgent,
					pproId: publicProfile.id
				}
				await setDoc(doc(db, "fcmTokens", currentToken), tokenObj);
				setCookie("fcmToken", "true", 30);
				_.set(userProfile, 'devices', currentToken);
				await saveUserProfile();
				window.location.replace('/apps/rodeoFlo/users/home.html');
			} else {
				// Show permission request UI
				console.log('No registration token available. Request permission to generate one.');
			}
		})
		.catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
		});
}

 

/**
 * Enable Notifications prompt
 * Easier to have a consistent interface and force all to manually click for permission
 * Forwards to home after asking....
 */
/* async function enableNotifications() {
	console.log("Running enableNotifications....");
	try {
		const permission = await Notification.requestPermission();
		if (permission === "granted") {
			console.log("Requested and approved.");
			setNotificationStatus("granted");
			if (checkCookie("fcmToken")) {
				console.log("fcmToken registered");
				window.location.replace('/apps/rodeoFlo/users/home.html');
			} else {
				console.log("no cookie found...registering device.");
				if ('serviceWorker' in navigator) {
					console.log("2. Registering service worker...");
					navigator.serviceWorker.register('../../../firebase-messaging-sw.js', { scope: '/' })
						.then( async (registration) => {		
							console.log('Service Worker registered:', registration);
							let messaging = getMessaging();
							getToken(messaging, {vapidKey: "BMoCn8WXGkDBxqOQTEvWFoMqyhoL0WsmX-EEniclbpVSJFeXwJfvsy3veMjq1LRuyf6SZvm4X8UvKxU7w0b1gI4"})
								.then( async (currentToken) => {
									console.log(currentToken);
									if (currentToken) {
										let tokenObj = {
											created: Date.now(),
											device: navigator.userAgent,
											pproId: publicProfile.id
										}
										await setDoc(doc(db, "fcmTokens", currentToken), tokenObj);
										setCookie("fcmToken", "true", 30);
										_.set(userProfile, 'devices', currentToken);
										await saveUserProfile();
										window.location.replace('/apps/rodeoFlo/users/home.html');
									} else {
										// Show permission request UI
										console.log('No registration token available. Request permission to generate one.');
										window.location.replace('/apps/rodeoFlo/users/home.html');
									}
								})
								.catch((err) => {
									console.log('An error occurred while retrieving token. ', err);
									window.location.replace('/apps/rodeoFlo/users/home.html');
								});
						})
						.catch((error) => {
							console.error('Service Worker registration failed:', error);
							window.location.replace('/apps/rodeoFlo/users/home.html');
						});
				} else {
					reject("no service worker found in if statement...");
					console.log("no service worker found...");
					window.location.replace('/apps/rodeoFlo/users/home.html');
				}
			}
		} else if (permission === "denied") {
			console.log("Notification NOT approved.");
			setNotificationStatus("denied");
			window.location.replace('/apps/rodeoFlo/users/home.html');
		}
	} catch (error) {
		console.log(error);
	}
} */



function enableNotifications() {
	console.log("Running enableNotifications....");
	Notification.requestPermission()
		.then( (permission) => {
			if (permission === "granted") {
				console.log("Requested and approved.");
				setNotificationStatus("granted");
				if (checkCookie("fcmToken")) {
					console.log("fcmToken registered");
					window.location.replace('/apps/rodeoFlo/users/home.html');
				} else {
					console.log("no cookie found...registering device.");
					//getFCMToken();
		
				}
			} else if (permission === "denied") {
				console.log("Notification NOT approved.");
				setNotificationStatus("denied");
				window.location.replace('/apps/rodeoFlo/users/home.html');
			}
		})
		.catch( (error) => {
			console.log(error);
			window.location.replace('/apps/rodeoFlo/users/home.html');
		})
}


function getLoginStatus() {
	let status = localStorage.getItem("loginStatus");
	switch(status) {
		case "pending":
			console.log('login pending...');
			document.getElementById('signIn').classList.add('d-none');
			document.getElementById('pending').classList.remove('d-none');
			document.getElementById('signedIn').classList.add('d-none');
			return "pending"
		case "signedIn":
			console.log('login signed IN...');
			document.getElementById('signIn').classList.add('d-none');
			document.getElementById('pending').classList.add('d-none');
			document.getElementById('signedIn').classList.remove('d-none');
			return "true"
		default:
			console.log('login NOT signed in...');
			document.getElementById('signIn').classList.remove('d-none');
			document.getElementById('pending').classList.add('d-none');
			document.getElementById('signedIn').classList.add('d-none');
			return "false";
	}
}


const addListeners = () => {
    //document.getElementById('signUp').addEventListener('click', signUp);
    document.getElementById('signUpGoogle').addEventListener('click', signUpGoogle);
    //document.getElementById('signUpFacebook').addEventListener('click', signUpFacebook);
	//$('#enableNotifications').on('click', enableNotifications);
}

docReady(addListeners);
docReady(getLoginStatus);

