/* eslint-disable */

/***
 * @param {*} currencyCode always USD for now....
 */
export function formatCurrency(amount, currencyCode, locale = 'en-US') {
	// Default to 0 if amount is not a number
	let numToParse = 0;
	if (!isNaN(amount)) numToParse = amount;
	

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currencyCode
	}).format(numToParse);
}
/**
 * 
 * @param {*} number any 10 digit number
 * @returns string of prettified phone number like (555) 123-1212
 */
export function formatPhoneNumber(number) {
    // Ensure the input is a string and remove any non-digit characters
    const cleaned = ('' + number).replace(/\D/g, '');
    
    // Check if the cleaned number is 10 digits
    if (cleaned.length === 10) {
        // Format the number
        const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        return formatted;
    } else {
        // Return the original number if it's not 10 digits
        return number;
    }
}

export function setCookie(name, value, days) {
	const date = new Date();
	date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // days to milliseconds
	const expires = "expires=" + date.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function checkCookie(cookieName) {
	const cookies = document.cookie.split(';');
	for (const cookie of cookies) {
	  const [key, value] = cookie.trim().split('=');
	  if (key === cookieName) {
		return true; // Cookie exists
	  }
	}
	return false; // Cookie doesn't exist
}

export function deleteCookie(name) {
	// Set the cookie with the same name and a past expiration date
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function labelValidationError(elemId) {
	let errElem = $(`#${elemId}`).siblings().filter('.my-error-tip');
	errElem.remove();

	let labelError = `<span class='text-danger my-error-tip'></span>`;
	$(`label[for='${elemId}']`).append(labelError);
}