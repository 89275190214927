/* eslint-disable */
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
	apiKey: "AIzaSyBzrNwJUNB97O_TBfKc6cIzWOL1wbvERUM",
	authDomain: "rodeoflo.com", // "rodeoflo.firebaseapp.com"
	databaseURL: "https://rodeoflo-default-rtdb.firebaseio.com",
	projectId: "rodeoflo",
	storageBucket: "rodeoflo.appspot.com",
	messagingSenderId: "536269144581",
	appId: "1:536269144581:web:9ec25a2342614c8390126c",
	measurementId: "G-JDY8ZXJCQV"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
