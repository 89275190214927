/* eslint-disable */


// memoized locally stored env vars....
let _env = {};
export const env = () => {
	if (Object.keys(_env).length === 0) { // nothing loaded
		console.log("env has not properties yet...");
		_env = JSON.parse(localStorage.getItem("localEnv"));
		// if nothing stored then create new
		if (_env === null) {
			console.log("nothing is stored...")
			// create new env var
			setBrowserInfo();
			console.log(_env);
			localStorage.setItem("localEnv", JSON.stringify(_env));
		}
	}
	return _env; //already loaded.
}

export function setNotificationStatus(status) {
	_env.status = status;
	saveLocalEnv();
}

function saveLocalEnv() {
	localStorage.setItem('localEnv', JSON.stringify(_env));
}

function setBrowserInfo() {
	const userAgent = navigator.userAgent;
  
	// Browser detection (based on user agent)
	let browser;
	if (userAgent.indexOf("Chrome") > -1) {
	  browser = "Chrome";
	} else if (userAgent.indexOf("Firefox") > -1) {
	  browser = "Firefox";
	} else if (userAgent.indexOf("Safari") > -1) {
	  browser = "Safari";
	} else if (userAgent.indexOf("Edge") > -1) {
	  browser = "Edge";
	} else {
	  browser = "Unknown";
	}
  
	// Platform detection (limited accuracy)
	let platform = "unknown";
	if (userAgent.indexOf("Win") > -1) {
	  platform = "Windows";
	}
	if (userAgent.indexOf("Mac") > -1) {
	  platform = "MacOS";
	}
	if (userAgent.indexOf("Linux") > -1) {
	  platform = "Linux";
	} 
	if (userAgent.indexOf("Android") > -1) {
	  platform = "Android";
	}
	if (userAgent.indexOf("iPhone") > -1) {
	  platform = "iPhone";
	}
	if (userAgent.indexOf("iPad") > -1) {
		platform = "iPad";
	}
  
	// Feature detection (more reliable)
	const isChromium = !!window.chrome; // For Chromium-based browsers (Chrome, Edge)
  
	// Return an object with the information
	_env.browser = browser;
	_env.platform = platform;
	_env.isChromium = isChromium;
	_env.userAgent = userAgent;
}
  
const browserInfo = setBrowserInfo();
console.log(browserInfo);