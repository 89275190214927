/* eslint-disable */
import { doc, setDoc } from "firebase/firestore";
import { db } from "./firebaseInit";
import { getAuth } from "firebase/auth";

const auth = getAuth();
let userProfile;
let publicProfile;
let activeOrg;

export { userProfile, publicProfile, activeOrg };

try {
	activeOrg = JSON.parse(sessionStorage.getItem('activeOrg'));
} catch (error) {
	console.log(error)
}

try {
	userProfile = JSON.parse(localStorage.getItem("userProfile"));
} catch (error) {
	console.log("User profile does not exists...redirect here?");
	userProfile = null;
}

try {
	publicProfile = JSON.parse(localStorage.getItem("publicProfile"));
} catch {
	console.log("no public profile saved locally");
	publicProfile = null;
}

export function setActiveUserProfile(profile) {
	userProfile = profile;
	localStorage.setItem("userProfile", JSON.stringify(userProfile));
	
}

/**
 * Sets the public profile and stores it locally (does not save it to db)
 * 
 * @param {*} profile public profile object to set active
 */
export function setActivePublicProfile(profile) {
	publicProfile = profile;
	localStorage.setItem("publicProfile", JSON.stringify(publicProfile));
}

/// FULL save...replaces full user document
// TODO remove setting the localstorage since the onsnapshot in appfb.js does this?
export async function saveUserProfile() {
	localStorage.setItem("userProfile", JSON.stringify(userProfile));
	await setDoc(doc(db, "users", userProfile.uid), userProfile);
	console.log("Profile Saved: ");
}

/**
 * saves the current public profile to db (run setActivePublicProfile first?)
 */
export async function savePublicProfile() {
	//localStorage.setItem("publicProfile", JSON.stringify(publicProfile));
	await setDoc(doc(db, "publicProfiles", publicProfile.id), publicProfile);
	console.log("Public Profile Saved: ");
}